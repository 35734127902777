.App {
  text-align: center;
}

input:-internal-autofill-selected {
  appearance: menulist-button;
  background-image: none !important;
  background-color: -internal-light-dark(rgb(5, 87, 230), rgba(240, 44, 10, 0)) !important;
  color: fieldtext !important;
}
